/* Google Font Import */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Agu+Display&family=Mukta+Malar:wght@200;300;400;500;600;700;800&family=Noto+Serif+Tamil:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Agu+Display&family=Handlee&family=Mukta+Malar:wght@200;300;400;500;600;700;800&family=Noto+Serif+Tamil:ital,wght@0,100..900;1,100..900&display=swap");

.handlee-normal {
  font-family: "Handlee", cursive;
  font-weight: 400;
  font-style: normal;
}
.handlee-medium {
  font-family: "Handlee", cursive;
  font-weight: 500;
  font-style: normal;
}
.handlee-bold {
  font-family: "Handlee", cursive;
  font-weight: 600;
  font-style: normal;
}
.handlee-extrabold {
  font-family: "Handlee", cursive;
  font-weight: 900;
  font-style: normal;
}

.agu-normal {
  font-family: "Agu Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "MORF" 0;
}

.agu-medium {
  font-family: "Agu Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "MORF" 0;
}
.agu-bold {
  font-family: "Agu Display", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "MORF" 0;
}

/* Scroll Bar Customization */
::-webkit-scrollbar {
  width: 0.4rem;
}
::-webkit-scrollbar-track {
  background-color: #fff7ed;
}
::-webkit-scrollbar-thumb {
  background-color: #122f39;
  border-radius: 5px;
}

/* tailwind Initialization */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Other Styles */
.container {
  @apply max-w-7xl mx-auto px-5 sm:px-6 lg:px-8;
}

.link-dt {
  @apply font-body font-normal text-[#666] hover:text-primary hover:font-semibold cursor-pointer;
}

.link-dt-active {
  @apply font-semibold text-primary;
}

.link-mp-active {
  @apply font-semibold text-primary;
}

.link-mp {
  @apply text-xl font-heading border-b-2 border-[#ccc] py-2;
}

.gradient {
  @apply bg-gradient-to-br from-tertary/10 to-orange-300/10;
}

.theam-grad {
  @apply bg-gradient-to-br from-transparent to-tertary/10;
}

.contact-inp {
  @apply w-full bg-gray-200 p-3 rounded-xl font-body font-medium text-base md:text-lg transition-all outline-tertary/70;
}

/* Module Circle Animation */
.module::after {
  @apply content-[''] block absolute size-[150px] rounded-full backdrop-blur-lg z-[-10] bg-gradient-to-br from-secondary/40 to-transparent transition-transform duration-500 ease-in-out;
  transform: translate(
    calc(var(--circle-x) * 0.02),
    calc(var(--circle-y) * 0.02)
  );
}

/* User Not Select */
.noselect {
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
}

/* Background Images Container */
.gradient {
  position: relative;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.transition-opacity {
  transition: opacity 1s ease-in-out;
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}
